<template>
  <view-item title="投诉分析">
    <en-card body-class="h-full flex flex-col gap-6" class="h-full">
      <en-form inline label-position="left">
        <en-form-item label="投诉日期">
          <en-date-picker
            v-model:start="form.data.startComplaintDate"
            v-model:end="form.data.endComplaintDate"
            type="daterange"
            @change="summary.get"
          ></en-date-picker>
        </en-form-item>

        <en-form-item>
          <en-button @click="form.clear.click">清空</en-button>
        </en-form-item>
      </en-form>

      <div class="bg-[var(--el-color-primary-light-8)] p-4 rounded flex justify-around items-center">
        <div>
          <span>收到投诉总单数：</span>
          <span>{{ summary.summariesByComplaintWaySummary.count }}单</span>
        </div>
        <div>
          <span>有效投诉单数：</span>
          <span>{{ summary.summariesByComplaintWaySummary.validCount }}单</span>
        </div>
        <div>
          <span>已解决有效投诉单数：</span>
          <span>{{ summary.summariesByComplaintWaySummary.totalSolvedCount }}单</span>
        </div>
      </div>

      <div class="flex-1 overflow-auto">
        <en-scrollbar view-class="h-full grid grid-cols-2 grid-rows-2 gap-6">
          <en-card body-class="h-full flex flex-col gap-4">
            <div>投诉来源分析</div>
            <en-table :data="summary.data.summariesByComplaintWay" :loading="summary.loading">
              <en-table-column label="排名(按有效投诉单)" type="index" width="160"></en-table-column>
              <en-table-column label="投诉来源" prop="way.message"></en-table-column>
              <en-table-column label="收到投诉单数" prop="totalCount"></en-table-column>
              <en-table-column label="有效投诉单数" prop="validCount"></en-table-column>
              <en-table-column label="已解决有效投诉单数" prop="solvedCount" width="160"></en-table-column>
              <en-table-column label="有效投诉占比" prop="validRate">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintSummaryRawDto'] }">
                  {{ formatPercent(row.validRate) }}
                </template>
              </en-table-column>
            </en-table>
          </en-card>

          <en-card body-class="h-full flex flex-col gap-4">
            <div>责任人统计分析</div>
         
            <en-chart
              :option="{
                series: [{ type: 'pie' }],
                dataset: {
                  dimensions: ['label', 'value'],
                  source: [
                    { label: '有效投诉单数', value: summary.summariesByComplaintWaySummary.validCount },
                    { label: '无效投诉单数', value: summary.summariesByComplaintWaySummary.invalidCount },
                    { label: '待定投诉单数', value: summary.summariesByComplaintWaySummary.pendingCount },
                  ]
                }
              }"
              :loading="summary.loading"
              class="flex-1"
            ></en-chart>
          </en-card>

          <en-card body-class="h-full flex flex-col gap-4">
            <div>投诉对象分析</div>
            <en-table :data="summary.data.summariesByComplaintTarget" :loading="summary.loading">
              <en-table-column label="排名(按有效投诉单数)" type="index" width="170"></en-table-column>
              <en-table-column label="投诉对象" prop="target.message"></en-table-column>
              <en-table-column label="收到投诉单数" prop="totalCount"></en-table-column>
              <en-table-column label="有效投诉单数" prop="validCount"></en-table-column>
              <en-table-column label="已解决有效投诉单数" prop="solvedCount" width="160"></en-table-column>
              <en-table-column label="有效投诉占比" prop="validRate">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintSummaryRawDto'] }">
                  {{ formatPercent(row.validRate) }}
                </template>
              </en-table-column>
            </en-table>
          </en-card>

          <en-card body-class="h-full flex flex-col gap-4">
            <div>投诉对象分析</div>
            <en-chart
              :option="{
                series: [{ type: 'pie' }],
                dataset: {
                  dimensions: ['label', 'value'],
                  source: (summary.data.summariesByComplaintTarget ?? []).map((item) => ({ label: item.target?.message, value: item.totalCount }))
                }
              }"
              :loading="summary.loading"
              class="flex-1"
            ></en-chart>
          </en-card>
        </en-scrollbar>
        <en-card body-class="h-full flex flex-col gap-4">
        <div>投诉来源分析</div>
        <en-table :data="summary.data.summariesByLiablePerson" :loading="summary.loading">
          <en-table-column label="排名(按单)" type="index" width="120"></en-table-column>
          <en-table-column label="员工姓名" prop="liablePerson.name"></en-table-column>
          <en-table-column label="手机号码" prop="liablePerson.cellphone" width="120"></en-table-column>
          <en-table-column label="班组" width="120">
  

            <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintSummaryRawDto'] }">
                  {{  row.liablePerson.workingTeamMembers.map(item =>item.workingTeam.name).join(',') }}
                </template>
          </en-table-column>
          <en-table-column label="收到投诉单数" prop="totalCount"></en-table-column>
          <en-table-column label="有效投诉单数" prop="validCount" width="120"></en-table-column>
          <en-table-column label="已解决有效投诉单数" prop="solvedCount" width="160"></en-table-column>
              <en-table-column label="有效投诉占比" prop="validRate">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintSummaryRawDto'] }">
                  {{ formatPercent(row.validRate) }}
                </template>
              </en-table-column>
            </en-table>
            </en-card>
      </div>
    </en-card>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { calculator } from '@enocloud/utils'

const startDate = dayjs().startOf('M').format('YYYY-MM-DD')
const endDate = dayjs().endOf('M').format('YYYY-MM-DD')

export default factory({
  config: {
    children: {
      form: {
        data: {
          startComplaintDate: startDate,
          endComplaintDate: endDate
        },
        children: {
          clear: {
            click() {
              this.form.data.startComplaintDate = startDate
              this.form.data.endComplaintDate = endDate
            }
          }
        }
      },
      summary: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/complaint/summary',
            data: 'object',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          summariesByComplaintWaySummary() {
            return (this.summary.data.summariesByComplaintWay ?? []).reduce(
              (res, item) => {
                res.count = calculator.add(res.count, item.totalCount)
                res.validCount = calculator.add(res.validCount, item.validCount)
                res.invalidCount = calculator.add(res.solvedCount, item.solvedCount)
                res.pendingCount = calculator.add(res.pendingCount, item.pendingCount)
                res.totalSolvedCount = calculator.add(res.totalSolvedCount, item.solvedCount)
                return res
              },
              { count: 0, validCount: 0, invalidCount: 0, pendingCount: 0 ,totalSolvedCount:0}
            )
          }
        }
      },
      dialog: {
        visible: false
      }
    }
  },

  mounted() {
    this.summary.get()
  }
})
</script>
